<template>
  <div>
    <h5 class="font-weight-normal">
      Condições de saúde relatadas
      <b-button
        id="popover-care-chart"
        variant="flat-primary"
        class="btn-icon rounded-circle p-0"
      >
        <feather-icon
          icon="InfoIcon"
          stroke="blue"
          size="16"
        />
      </b-button>
    </h5>
    <vue-apex-charts
      v-if="showChart"
      ref="chart"
      type="bar"
      class="chart-bariatric"
      height="265"
      width="100%"
      :options="dataChart.options"
      :series="dataChartSeries"
    />
    <p
      v-if="!showChart"
      class="m-3"
    >
      Você não possui pacientes vinculados para o período selecionado.
    </p>
    <b-popover
      target="popover-care-chart"
      variant="primary"
      triggers="click blur"
      custom-class="bariatric-popover"
    >
      <template #title>
        <h6 class="text-white m-0">
          Condições de saúde relatadas
        </h6>
      </template>
      <p>As 10 condições de saúde mais frequentes dos seus pacientes.</p>
    </b-popover>
  </div>
</template>

<script>
import {
  BPopover, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import caresChart from './caresChart'

export default {
  components: {
    BPopover,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    VueApexCharts,
  },

  props: {
    careChartsData: {
      type: Array,
      default: () => [],
    },
  },

  setup() {
    const showChart = ref(false)

    const dataChart = ref(caresChart)

    const dataChartSeries = ref([])

    return {
      showChart,
      caresChart,
      dataChart,
      dataChartSeries,
    }
  },
  watch: {
    careChartsData() {
      this.dataChart.options.xaxis.categories = []
      this.dataChartSeries = []
      this.dataChart.options.xaxis.categories.splice(0, this.dataChart.options.xaxis.categories.length)
      const data = []
      this.careChartsData.forEach(care => {
        this.dataChart.options.xaxis.categories.push(care.name)
        data.push(care.value.toFixed(2))
      })
      this.dataChartSeries.push({ data })
      if (this.careChartsData.length === 0) {
        this.showChart = false
        return
      }
      if (this.$refs.chart) {
        this.$refs.chart.refresh()
      }
      this.showChart = true
    },
  },
}
</script>

<style>
.chart-bariatric .apexcharts-legend{
  padding: 0;
}

.chart-bariatric .apexcharts-legend-marker {
  margin-right: 10px;
}
</style>
