<template>
  <div>
    <div
      class="p-1"
      style="background-color: #ffffff"
    >
      <h4>Aprove a carteirinha digital Bari+ dos seus pacientes</h4>
      <p>
        Nesta página, você pode aprovar, recusar ou editar os dados da solicitação da Carteirinha de Identificação do Paciente Bariátrico Bari+.
      </p>
    </div>
    <b-card class="mt-2">
      <div class="mt-3">
        <b-row>
          <b-col
            lg="5"
            cols="8"
          >
            <b-input-group>
              <b-form-input
                v-model="searchQuery"
                type="text"
                placeholder="Digite o nome do paciente"
                @keydown="onKeyDown"
              />
              <b-input-group-append>
                <b-button
                  variant="primary"
                  @click="refetchData"
                >
                  <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                  />
                  Buscar
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
        </b-row>
      </div>
      <b-row class="mt-4">
        <b-col>
          <b-card-title class="mt-1 text-gray-800">
            Lista de pacientes que solicitaram carteirinha
          </b-card-title>
        </b-col>
      </b-row>
      <b-row
        class="mb-2"
        align-h="start"
      >
        <b-col cols="12">
          <b-button
            class="mr-1"
            :disabled="(selectedCards.length < 1)"
            variant="outline-primary"
            @click="confirmAction('approved')"
          >
            Aprovar
          </b-button>
          <b-button
            variant="outline-danger"
            :disabled="(selectedCards.length < 1)"
            @click="confirmAction('refused')"
          >
            Recusar
          </b-button>
          <span v-if="selectedCards.length > 0"><b-button
            class="ml-2 mr-0"
            variant="link"
            @click="toggleAll(false)"
          ><feather-icon
            icon="XIcon"
            color="black"
          /></b-button>{{ selectedCards.length }} selecionado(s)</span>
        </b-col>
      </b-row>
      <b-table
        ref="refBariatricCardListTable"
        :items="fetchBariatricCards"
        :fields="tableColumns"
        primary-key="id"
        responsive
        show-empty
        :empty-text="emptyTextTable"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        class="bariatric-list-table"
      >
        <template #head(selected)>
          <b-form-checkbox
            v-model="checkedToggleAll"
            @change="toggleAll"
          />
        </template>
        <template #cell(selected)="data">
          <template v-if="data.item.status !=='WAITING'">
            <b-form-checkbox
              :disabled="true"
            />
          </template>
          <template v-else>
            <b-form-checkbox
              v-model="selectedCards"
              name="selected-rows"
              :value="data.item.id"
            />
          </template>
        </template>
        <template #cell(member_name)="data">
          <b-media vertical-align="center">
            <span class="text-gray-500 font-weight-bold">{{ data.item.member_name }}</span>
            <br>
            <template v-if="data.item.member_birthdate">
              <small>{{ formatDateBR(data.item.member_birthdate) }} - {{ data.item.member_age }} anos</small>
            </template>
          </b-media>
        </template>

        <template #cell(date_surgery_type)="data">
          <b-media vertical-align="center">
            <span class="text-gray-500 font-weight-bold">{{ formatDateBR(data.item.surgery_date) }}</span>
            <br>
            <template v-if="data.item.surgery_type">
              <small>{{ data.item.surgery_type }}</small>
            </template>
          </b-media>
        </template>
        <template #cell(created_at)="data">
          <b-media vertical-align="center">
            <span class="text-gray-500 font-weight-bold">{{ formatDateBR(data.item.created_at) }}</span>
          </b-media>
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveBariatricCardStatusVariant(data.item.status)}`"
          >
            {{ translateBr(data.item.status) }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div>
            <b-button
              :id="`tooltip-approved-${data.item.id}`"
              :disabled="data.item.status !== 'WAITING'"
              variant="link"
              @click="confirmActionForCard('approved', data.item.id)"
            >
              <feather-icon
                stroke="blue"
                icon="CheckCircleIcon"
                stroke-width="1"
                size="20"
              />
            </b-button>
            <b-tooltip
              :target="`tooltip-approved-${data.item.id}`"
              triggers="hover"
            >
              Aprovar
            </b-tooltip>
            <b-button
              :id="`tooltip-refused-${data.item.id}`"
              :disabled="data.item.status !== 'WAITING'"
              variant="link"
              @click="confirmActionForCard('refused', data.item.id)"
            >
              <feather-icon
                stroke="red"
                icon="XCircleIcon"
                stroke-width="1"
                size="20"
              />
            </b-button>
            <b-tooltip
              :target="`tooltip-refused-${data.item.id}`"
              placement="bottom"
              triggers="hover"
            >
              Rejeitar
            </b-tooltip>
            <b-button
              :id="`tooltip-edit-card-${data.item.id}`"
              :disabled="data.item.status !== 'WAITING'"
              variant="link"
              :to="{ name: 'card-detail', params: { id: data.item.id } }"
            >
              <feather-icon
                stroke="blue"
                icon="EditIcon"
                stroke-width="1"
                size="20"
              />
            </b-button>
            <b-tooltip
              :target="`tooltip-edit-card-${data.item.id}`"
              triggers="hover"
            >
              Editar
            </b-tooltip>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          />
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-center"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalCards"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1"
          >
            <label>Mostrar</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50 viewItens"
            />
            <label>itens</label>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <confirm-modal
      :is-approved="isApproved"
      @approved="updateCardStatus(selectedCards, 'APPROVED')"
      @refused="updateCardStatus(selectedCards, 'REFUSED')"
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardTitle,
  BTable, BMedia, BBadge, BPagination, BFormCheckbox, BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import confirmModal from '@core/components/bariatric/card/ConfirmModal.vue'
import useBariatricCardsList from './useBariatricCardsList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardTitle,
    BTable,
    BMedia,
    BBadge,
    BPagination,
    BFormCheckbox,
    confirmModal,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    // eslint-disable-next-line vue/no-unused-components
    BTooltip,
    vSelect,
  },

  setup() {
    const {
      fetchBariatricCards,
      tableColumns,
      perPage,
      currentPage,
      totalCards,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBariatricCardListTable,
      bariatricCards,
      selectedCards,
      checkedToggleAll,
      isApproved,
      emptyTextTable,
      refetchData,
      updateCardStatus,

      // UI
      resolveBariatricCardStatusVariant,

    } = useBariatricCardsList()

    const textPattern = /^[a-zA-Z\s]*$/

    return {
      fetchBariatricCards,
      tableColumns,
      perPage,
      currentPage,
      totalCards,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBariatricCardListTable,
      bariatricCards,
      selectedCards,
      checkedToggleAll,
      isApproved,
      emptyTextTable,
      refetchData,
      updateCardStatus,
      textPattern,

      // UI
      resolveBariatricCardStatusVariant,
    }
  },

  methods: {
    formatDateBR(value) {
      if (value === null) {
        return ''
      }
      return moment(value).format('DD/MM/YYYY')
    },
    translateBr(value) {
      switch (value) {
        case 'WAITING':
          return 'Pendente'
        case 'APPROVED':
          return 'Aprovado'
        case 'REFUSED':
          return 'Recusado'
        default:
          return ''
      }
    },
    toggleAll(checked) {
      this.selectedCards = checked ? this.bariatricCards.reduce((items, current) => {
        if (current.status === 'WAITING') {
          items.push(current.id)
          return items
        }
        return items
      }, []) : []

      this.checked = checked
    },
    confirmAction(action) {
      this.isApproved = action === 'approved'
      this.$bvModal.show('confirm-modal')
    },
    confirmActionForCard(action, cardId) {
      this.selectedCards = [cardId]
      this.confirmAction(action)
    },
    onKeyDown(event) {
      const { key } = event
      const pattern = /^[a-zA-Z\s]*$/

      if (!pattern.test(key) && key !== 'Backspace') {
        event.preventDefault()
      }
    },
  },
}
</script>
