<template>
  <div>
    <div
      class="p-1"
      style="background-color: #ffffff"
    >
      <h4>Indicadores individuais e nacionais</h4>
      <p>
        Nesta página, você pode visualizar o seu desempenho individual e os indicadores nacionais.
      </p>
    </div>
    <b-card class="mt-2">
      <b-row>
        <b-col>
          <h4 class="mb-2">
            Meus indicadores individuais
          </h4>
        </b-col>
      </b-row>
      <b-card class="card-bordered mt-1 mb-0">
        <p class="m-0">
          Filtrar indicadores individuais por tipo de solicitação:
        </p>
        <div class="form-group">
          <b-row>
            <div class="demo-inline-spacing ml-2">
              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="sourceRequestsSelected"
                  :aria-describedby="ariaDescribedby"
                  name="filters"
                  @change="updateFilters"
                >
                  <b-form-checkbox value="SUS">
                    SUS
                  </b-form-checkbox>
                  <b-form-checkbox value="PRIVATE">
                    Particular
                  </b-form-checkbox>
                  <b-form-checkbox
                    value="HEALTH_INSURANCE"
                    @change="enableSelecHealthInsurance"
                  >
                    Convênio
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form-group>
            </div>
            <b-col
              cols="12"
              class="mt-1"
              sm="5"
            >
              <v-select
                v-model="planIds"
                multiple
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="healthInsuranceOption"
                :disabled="healthInsuranceDisabled"
                placeholder="Selecione um ou mais convênios"
                :reduce="options => options.id"
                width="100%"
                @option:selected="updateFilters"
                @option:deselected="updateFilters"
              >
                <div slot="no-options">
                  Nenhum resultado encontrado.
                </div>
              </v-select>
            </b-col>
          </b-row>
        </div>
        <b-row>
          <b-col
            cols="12"
            sm="4"
            class="mt-1"
          >
            <bariatric-first-appointment-chart :charts-data="chartsData" />
          </b-col>
          <b-col
            cols="12"
            sm="3"
            class="mt-1"
          >
            <bariatric-average-surgery-time-card
              :average-days="averageDays"
              :loading="loading"
            />
            <bariatric-patients-surgery-done-card
              class="mt-2"
              :total-patients="totalPatients"
              :surgeries-performed="surgeriesPerformed"
              :loading="loading"
            />
          </b-col>
          <b-col
            cols="12"
            sm="5"
            class="mt-1"
          >
            <bariatric-cares-chart :care-charts-data="careChartsData" />
          </b-col>
        </b-row>
      </b-card>
    </b-card>
    <b-card class="mt-2">
      <b-row>
        <b-col>
          <h4>Indicadores nacionais</h4>
        </b-col>
        <b-embed
          type="iframe"
          src="https://lookerstudio.google.com/embed/reporting/250506a7-684f-495d-8675-6b17996b42be/page/p_yty88o2b8c"
        />
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BEmbed, BFormGroup, BFormCheckboxGroup, BFormCheckbox,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import BariatricFirstAppointmentChart from '../Bariatric-first-appointment-chart.vue'
import BariatricAverageSurgeryTimeCard from '../Bariatric-average-surgery-time-card.vue'
import BariatricPatientsSurgeryDoneCard from '../Bariatric-patients-surgery-done-card.vue'
import BariatricCaresChart from './charts/cares/Bariatric-cares-chart.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BEmbed,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BariatricFirstAppointmentChart,
    BariatricAverageSurgeryTimeCard,
    BariatricPatientsSurgeryDoneCard,
    BariatricCaresChart,

    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },

  setup() {
    const chartsData = ref({})
    const careChartsData = ref([])
    const averageDays = ref()
    const totalPatients = ref()
    const surgeriesPerformed = ref()
    const loading = ref(true)
    const timer = ref()
    const sourceRequestsSelected = ref([])
    const planIds = ref([])
    const healthInsuranceOption = ref([])
    const healthInsuranceDisabled = ref(true)
    const filters = ref({
      sourceRequests: sourceRequestsSelected.value,
      planIds: planIds.value,
    })

    function loadData() {
      this.$store.dispatch('bariatric/report', this.filters)
        .then(response => {
          const { data } = response.data
          chartsData.value = data.bariatric_journey_report_days_info
          averageDays.value = data.bariatric_journey_report_surgeries_info.average_days
          surgeriesPerformed.value = data.bariatric_journey_report_surgeries_info.surgeries_performed
          totalPatients.value = data.bariatric_journey_report_surgeries_info.total_patients
          careChartsData.value = data.bariatric_member_cares_report_info
          loading.value = false
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    function loadHealthInsurancePlans(filter) {
      this.$store.dispatch('bariatric/healthInsurancePlans', filter)
        .then(response => {
          const { data } = response.data
          healthInsuranceOption.value = data.health_insurance_plans_info
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    function enableSelecHealthInsurance(checkboxes) {
      if (checkboxes.find(checkbox => checkbox === 'HEALTH_INSURANCE')) {
        this.healthInsuranceDisabled = false
        return
      }
      this.planIds = []
      this.healthInsuranceDisabled = true
    }

    return {
      averageDays,
      totalPatients,
      surgeriesPerformed,
      loading,
      chartsData,
      sourceRequestsSelected,
      healthInsuranceOption,
      healthInsuranceDisabled,
      timer,
      planIds,
      filters,
      careChartsData,
      loadData,
      loadHealthInsurancePlans,
      enableSelecHealthInsurance,
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    this.loadHealthInsurancePlans()
  },
  methods: {
    updateFilters() {
      this.filters.sourceRequests = this.sourceRequestsSelected
      this.filters.planIds = this.planIds

      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.loadData()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.card-bordered {
  border: 1px solid #B9B9C3;
  border-radius: 6px;

  .card-body > p {
    display: inline-block;
    padding: 8px;
    background: #FFF;
    position: absolute;
    top: -20px;
  }
}

@media (max-width: 380px) {
  .card-bordered  .card-body > p {
      position: static;
  }
}
</style>
