<template>
  <div>
    <h5 class="font-weight-normal mb-3">
      Média de tempo até a cirurgia
      <b-button
        id="popover-card-average-surgery-time"
        variant="flat-primary"
        class="btn-icon rounded-circle p-0"
      >
        <feather-icon
          icon="InfoIcon"
          stroke="blue"
          size="16"
        />
      </b-button>
    </h5>

    <div v-if="loading">
      <b-skeleton
        animation="wave"
        width="20%"
        height="30px"
        type="text"
        class="mb-2"
      />
      <small class="d-block">
        <b-skeleton
          animation="wave"
          width="5%"
          type="text"
          class="mb-2"
        />
      </small>
    </div>
    <span
      v-else-if="!loading && averageDays !== null"
      class="h1 font-weight-bolder d-block text-gray-800"
    >
      <strong class="mb-2 d-block">{{ averageDays }}</strong>

      <small class="d-block">
        Dias
      </small>
    </span>
    <p v-else>
      Ops! Este conteúdo não pode ser exibido. Por favor verifique sua conexão com a internet e tente novamente.
    </p>

    <b-popover
      target="popover-card-average-surgery-time"
      variant="primary"
      triggers="click blur"
      custom-class="bariatric-popover"
    >
      <template #title>
        <h6 class="text-white m-0">
          Média de tempo até a cirurgia
        </h6>
      </template>
      <p>Consulte quantos dias, em média, seus pacientes levam até realizarem a cirurgia bariátrica.</p>
    </b-popover>
  </div>
</template>

<script>

import {
  BButton, BPopover, BSkeleton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BPopover,
    BSkeleton,
  },

  props: {
    averageDays: {
      type: Number,
      default: null,
    },

    loading: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" scoped>
  .card-average-surgery-time{
    h5 {
      line-height: 1.5rem;
    }
  }
</style>
