<template>
  <div>
    <h5 class="font-weight-normal">
      Dias após a <br>primeira consulta
      <b-button
        id="popover-first-appointment-chart"
        variant="flat-primary"
        class="btn-icon rounded-circle p-0"
      >
        <feather-icon
          icon="InfoIcon"
          stroke="blue"
          size="16"
        />
      </b-button>
    </h5>
    <vue-apex-charts
      v-if="showChart"
      type="donut"
      class="chart-bariatric"
      height="250"
      :options="chartsjsDataBariatric.donutChart.chartOptions"
      :series="dataChartSeries"
    />
    <p
      v-if="!showChart"
      class="m-3"
    >
      Você não possui pacientes vinculados para o período selecionado.
    </p>
    <b-popover
      target="popover-first-appointment-chart"
      variant="primary"
      triggers="click blur"
      custom-class="bariatric-popover"
    >
      <template #title>
        <h6 class="text-white m-0">
          Dias após a primeira consulta
        </h6>
      </template>
      <p>Saiba o percentual de seus pacientes em relação aos dias após a primeira consulta.</p>
    </b-popover>
  </div>
</template>

<script>
import {
  BPopover, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import VueApexCharts from 'vue-apexcharts'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import chartsjsDataBariatric from './chartsjsDataBariatric'

export default {
  components: {
    BPopover,
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,

    VueApexCharts,
  },

  props: {
    chartsData: {
      type: Object,
      default: () => {},
    },
  },

  setup() {
    const showChart = ref(false)

    const dataChartSeries = ref([])

    return {
      showChart,
      chartsjsDataBariatric,
      dataChartSeries,
    }
  },
  watch: {
    chartsData() {
      this.dataChartSeries = []
      this.dataChartSeries.push(this.chartsData.up_to_30)
      this.dataChartSeries.push(this.chartsData['31_to_60'])
      this.dataChartSeries.push(this.chartsData['61_to_90'])
      this.dataChartSeries.push(this.chartsData.more_than_90)
      if (this.dataChartSeries.every(item => item === 0)) {
        this.showChart = false
        return
      }
      this.showChart = true
    },
  },
}
</script>

<style>
.chart-bariatric .apexcharts-legend{
  padding: 0;
}

.chart-bariatric .apexcharts-legend-marker {
  margin-right: 10px;
}
</style>
