const chartColors = {
  donut: {
    series1: '#56B4E9',
    series2: '#109E73',
    series3: '#E69F27',
    series4: '#D55E00',
  },
}

export default {
  donutChart: {
    series: [],
    chartOptions: {
      legend: {
        height: '100%',
        position: 'bottom',
        horizontalAlign: 'center',
        show: true,
        itemMargin: {
          vertical: 3,
        },
        fontSize: '12px',
        fontFamily: 'Montserrat',
        formatter(seriesName, opts) {
          const percent = opts.w.globals.seriesPercent[opts.seriesIndex]
          let percentValue = ''
          if (!Number.isNaN(percent[0])) {
            percentValue = percent
          } else {
            percentValue = 0
          }
          // eslint-disable-next-line radix
          return [`<span>${seriesName}</span>`, '', `<span style="margin-left:200px;" class="font-weight-bold text-gray-800">${parseInt(percentValue)}%</span>`]
        },
      },
      colors: [
        chartColors.donut.series1,
        chartColors.donut.series2,
        chartColors.donut.series3,
        chartColors.donut.series4,
      ],
      stroke: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          position: 'center',
          // offsetY: -10,
          // itemMargin: {
          //   horizontal: 20, vertical: 0,
          // },
          // customScale: 1.1,
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '0.8rem',
                fontFamily: 'Montserrat',
                offsetY: 0,
              },
              value: {
                fontSize: '1rem',
                offsetY: 0,
                fontFamily: 'Montserrat',
                formatter(val, value) {
                  const total = value.globals.seriesTotals.reduce((a, b) => a + b, 0)
                  let percent = ''
                  if (total !== 0) {
                    percent = (val * 100) / total
                  } else {
                    percent = 0
                  }
                  // eslint-disable-next-line radix
                  return `${parseInt(percent)}%`
                },
              },
              // total: {
              //   show: true,
              //   fontSize: '1.5rem',
              //   formatter() {
              //   },
              // },
            },
          },
        },
      },
      labels: ['Até 30 dias', '31 a 60 dias', '61 a 90 dias', '+ de 90 dias'],
      responsive: [
        {
          breakpoint: 1470,
          options: {
            chart: {
              height: 270,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'center',
              itemMargin: {
                vertical: 3,
              },
              formatter(seriesName, opts) {
                const percent = opts.w.globals.seriesPercent[opts.seriesIndex]
                let percentValue = ''
                if (!Number.isNaN(percent[0])) {
                  percentValue = percent
                } else {
                  percentValue = 0
                }
                // eslint-disable-next-line radix
                return [`<span>${seriesName}</span>`, ' ', `<span class="d-inline-block ml-3 font-weight-bold text-gray-800">${parseInt(percentValue)}%</span>`]
              },

            },
          },
        },
        {
          breakpoint: 1300,
          options: {
            chart: {
              height: 270,
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'center',
              fontSize: '13px',
              itemMargin: {
                vertical: 3,
              },
              formatter(seriesName, opts) {
                const percent = opts.w.globals.seriesPercent[opts.seriesIndex]
                let percentValue = ''
                if (!Number.isNaN(percent[0])) {
                  percentValue = percent
                } else {
                  percentValue = 0
                }
                // eslint-disable-next-line radix
                return [`<span >${seriesName}</span>`, ' ', `<span class="d-inline-block ml-3 font-weight-bold text-gray-800">${parseInt(percentValue)}%</span>`]
              },
            },
          },
        },
      ],
    },
  },
}
