import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useProtocolModelList() {
  // Use toast
  const toast = useToast()

  const refProtocolModelListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: 'Protocolo', sortable: true },
    { key: 'created_at', label: 'Data de criação', sortable: true },
    { key: 'selection', label: 'Selecionar' },
  ]
  const perPage = ref(10)
  const totalProtocolModels = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const protocolModelSelected = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refProtocolModelListTable.value ? refProtocolModelListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalProtocolModels.value,
    }
  })

  const refetchData = () => {
    refProtocolModelListTable.value.refresh()
  }

  watch([currentPage, perPage, sortBy, isSortDirDesc], () => {
    refetchData()
  })

  const listProtocolModels = (ctx, callback) => {
    store
      .dispatch('protocolModel/fetchProtocolModels', {
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDirection: !isSortDirDesc.value ? 'desc' : 'asc',
      })
      .then(response => {
        const { bariatric_protocol_models_info, total } = response.data.data

        if (total > 0) {
          store.commit('survey/UPDATE_BARIATRIC_SURVEY', true)
        }

        callback(bariatric_protocol_models_info)
        totalProtocolModels.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ops... Algo deu errado, tente novamente',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    listProtocolModels,
    tableColumns,
    perPage,
    currentPage,
    totalProtocolModels,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refProtocolModelListTable,
    protocolModelSelected,

    refetchData,
  }
}
