<template>
  <div
    class="p-1"
    style="background-color: #ffffff"
  >
    <div class="m-2 mt-3">
      <b-row>
        <b-col cols="5">
          <b-button
            variant="outline-primary"
            @click="showFilters"
          >
            <feather-icon
              icon="FilterIcon"
              class="mr-50"
            />
            <span class="d-none d-xl-inline">Filtrar</span>
          </b-button>
        </b-col>
        <b-col
          lg="7"
          cols="7"
          class="text-right"
        >
          <b-input-group>
            <b-form-input
              v-model="searchQuery"
              type="text"
              placeholder="Digite o nome do paciente"
            />
            <b-input-group-append>
              <b-button variant="primary">
                <feather-icon
                  icon="SearchIcon"
                />
                <span class="d-none d-xl-inline ml-50">Buscar</span>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <template v-if="viewFilters">
        <div class="mt-1 p-2 border">
          <b-row>
            <b-col
              lg="3"
              cols="12"
            >
              <div class="d-flex">
                <v-select
                  v-model="statusFilter"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="statusOptions"
                  :reduce="statusOptions => statusOptions.key"
                  placeholder="Status"
                  multiple
                  class="w-100"
                  label="name"
                />
                <b-button
                  id="popover-status"
                  variant="outline-light"
                  class="btn-no-hover rounded-circle p-0 ml-1"
                >
                  <feather-icon
                    icon="InfoIcon"
                    stroke="blue"
                    size="16"
                  />
                </b-button>
              </div>
            </b-col>
            <b-popover
              target="popover-status"
              variant="primary"
              triggers="click blur"
              custom-class="bariatric-popover"
            >
              <template #title>
                <h6 class="text-white m-0">
                  Status dos pacientes
                </h6>
              </template>
              <p><strong>Ativo: </strong>Em processo para cirurgia bariátrica após o cadastro do protocolo pré-bariatrica.</p>
              <p><strong>Cadastro de protocolo: </strong>Aguardando o registro do protocolo pré-bariatrica para iniciar a jornada.</p>
              <p><strong>Arquivado: </strong>Jornada foi interrompida em qualquer etapa e não são considerados nos indicadores.</p>
              <p><strong>Cirurgia realizada: </strong>Concluiram a jornada e passaram pela cirurgia bariátrica.</p>
            </b-popover>
            <b-col
              lg="4"
              cols="7"
            >
              <b-form
                style="margin-top:10px;"
                class="text-lg-right"
              >
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="sourceRequestsSelected"
                  name="filters"
                >
                  <b-form-checkbox value="SUS">
                    SUS
                  </b-form-checkbox>
                  <b-form-checkbox value="PRIVATE">
                    Particular
                  </b-form-checkbox>
                  <b-form-checkbox
                    value="HEALTH_INSURANCE"
                    @change="enableSelecHealthInsurance"
                  >
                    Convênio
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-form>
            </b-col>
            <b-col
              lg="5"
              cols="12"
            >
              <div class="d-flex">
                <v-select
                  v-model="healthInsuranceIds"
                  multiple
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="healthInsuranceOptions"
                  :disabled="healthInsuranceDisabled"
                  placeholder="Selecione um ou mais convênios"
                  :reduce="options => options.id"
                  class="w-100"
                >
                  <div slot="no-options">
                    Nenhum resultado encontrado.
                  </div>
                </v-select>
                <b-button
                  id="popover-health-insurance"
                  variant="outline-light"
                  class="btn-no-hover rounded-circle p-0 ml-1"
                >
                  <feather-icon
                    icon="InfoIcon"
                    stroke="blue"
                    size="16"
                  />
                </b-button>
              </div>
            </b-col>
            <b-popover
              target="popover-health-insurance"
              variant="primary"
              triggers="click blur"
              custom-class="bariatric-popover"
            >
              <template #title>
                <h6 class="text-white m-0">
                  Tipo de solicitação
                </h6>
              </template>
              <p>Filtre os pacientes da lista por tipo de solicitação e selecione entre os convênios cadastrados.</p>
            </b-popover>
          </b-row>
          <b-row>
            <b-col
              lg="5"
              cols="12"
              class="d-flex mt-1"
            >
              <v-select
                v-model="clincIds"
                multiple
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="name"
                :options="clinicOptions"
                placeholder="Local da 1ª consulta"
                :reduce="options => options.id"
                class="w-100"
              >
                <div slot="no-options">
                  Nenhum resultado encontrado.
                </div>
              </v-select>
              <b-button
                id="popover-clinic"
                variant="outline-light"
                class="btn-no-hover rounded-circle p-0 ml-1"
              >
                <feather-icon
                  icon="InfoIcon"
                  stroke="blue"
                  size="16"
                />
              </b-button>
            </b-col>

            <b-popover
              target="popover-clinic"
              variant="primary"
              triggers="click blur"
              custom-class="bariatric-popover"
            >
              <template #title>
                <h6 class="text-white m-0">
                  Local da 1ª consulta
                </h6>
              </template>
              <p>Ao cadastrar ou atualizar os dados do paciente, inclua o  local da primeira consulta e filtre por localidade, "Local não cadastrado" na lista indica a necessidade de inserir essa informação.</p>
            </b-popover>

            <b-col
              lg="7"
              cols="12"
              class="d-flex mt-1"
            >
              <b-button-group class="filter-range-days m-0">
                <b-button
                  variant="outline-primary"
                  :class="{ active: activeRageDays === '<30'}"
                  size="sm"
                  @click="rangeDays('<30')"
                >
                  Até 30 dias
                </b-button>
                <b-button
                  variant="outline-primary"
                  :class="{ active: activeRageDays === '>31<60' }"
                  size="sm"
                  @click="rangeDays('>31<60')"
                >
                  31 a 60 dias
                </b-button>
                <b-button
                  variant="outline-primary"
                  :class="{ active: activeRageDays === '>61<90' }"
                  size="sm"
                  @click="rangeDays('>61<90')"
                >
                  61 a 90 dias
                </b-button>
                <b-button
                  variant="outline-primary"
                  :class="{ active: activeRageDays === '>91'}"
                  size="sm"
                  @click="rangeDays('>91')"
                >
                  + 90 dias
                </b-button>
              </b-button-group>
              <b-button
                id="popover-range-days"
                variant="outline-light"
                class="btn-no-hover rounded-circle p-0 ml-1"
              >
                <feather-icon
                  icon="InfoIcon"
                  stroke="blue"
                  size="16"
                />
              </b-button>
            </b-col>
            <b-popover
              target="popover-range-days"
              variant="primary"
              triggers="click blur"
              custom-class="bariatric-popover"
            >
              <template #title>
                <h6 class="text-white m-0">
                  Dias após a 1ª consulta
                </h6>
              </template>
              <p>Filtre os pacientes da lista por intervalos de dias após a 1ª consulta.</p>
            </b-popover>
          </b-row>
          <b-row class="mt-1">
            <b-col>
              <b-button
                variant="outline-primary"
                :disabled="!hasFilter"
                @click="clearFilters"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span>Limpar filtros</span>
              </b-button>
            </b-col>
          </b-row>
        </div>
      </template>
    </div>

    <b-card-header
      class="pt-0"
      header-bg-variant="white"
    >
      <b-row>
        <b-col>
          <b-card-title class="mt-1 text-gray-800">
            Lista de pacientes na jornada da cirurgia bariátrica
          </b-card-title>
        </b-col>
      </b-row>
    </b-card-header>

    <div
      v-if="resolveQueryStringFilter(queryStringFilter)"
      class="notification-filter rounded m-1 p-1"
    >
      <b-row align-v="center">
        <b-col cols="9">
          <span class="font-weight-bold">{{ resolveQueryStringFilter(queryStringFilter) }}</span>
        </b-col>
        <b-col
          cols="3 text-right"
        >
          <a @click="clearQueryStringFilter()">limpar filtro</a>
        </b-col>
      </b-row>
    </div>

    <b-table
      ref="refBariatricListTable"
      :items="fetchBariatrics"
      :fields="tableColumns"
      primary-key="id"
      responsive
      show-empty
      :empty-text="noDataTableText"
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      class="bariatric-list-table"
    >
      <template #head(actions)="data">
        <span class="ml-2">{{ data.label }}</span>
      </template>
      <template #head(days_passed)="data">
        <span>{{ data.label }}</span>
        <b-button
          id="popover-range-days-column"
          variant="outline-light"
          class="btn-no-hover rounded-circle p-0 ml-1"
        >
          <feather-icon
            icon="InfoIcon"
            stroke="blue"
            size="16"
          />
        </b-button>
        <b-popover
          target="popover-range-days-column"
          variant="primary"
          triggers="click blur"
          custom-class="bariatric-popover"
        >
          <template #title>
            <h6 class="text-white m-0">
              Dias após a 1ª consulta
            </h6>
          </template>
          <b-row class="m-1">
            <span
              class="badge-dote"
              style="background-color: #56B4E9;"
            />
            <span class="ml-2">até 30 dias</span>
          </b-row>
          <b-row class="m-1">
            <span
              class="badge-dote"
              style="background-color: #119E73;"
            />
            <span class="ml-2">31 a 60 dias</span>
          </b-row>
          <b-row class="m-1">
            <span
              class="badge-dote"
              style="background-color: #E69F28;"
            />
            <span class="ml-2">61 a 90 dias</span>
          </b-row>
          <b-row class="m-1">
            <span
              class="badge-dote"
              style="background-color: #D55E1B;"
            />
            <span class="ml-2">+ de 90 dias</span>
          </b-row>
        </b-popover>
      </template>
      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-button
          v-if="data.item.bariatric_journey_status === 'REGISTER_PROTOCOL'"
          :id="`tooltip-register-${data.item.bariatric_journey_id}`"
          variant="link"
          @click="action(data.item, 'register')"
        >
          <b-img :src="require('@/assets/images/icons/Icon-file-plus.svg')" />
        </b-button>
        <b-button
          v-else
          :id="`tooltip-edit-${data.item.bariatric_journey_id}`"
          variant="link"
          @click="action(data.item, 'detail')"
        >
          <b-img :src="require('@/assets/images/icons/Icon-edit.svg')" />
        </b-button>
        <b-tooltip
          :target="`tooltip-register-${data.item.bariatric_journey_id}`"
          triggers="hover"
        >
          Cadastrar protocolo
        </b-tooltip>
        <b-tooltip
          :target="`tooltip-edit-${data.item.bariatric_journey_id}`"
          triggers="hover"
        >
          Detalhes do paciente
        </b-tooltip>
        <b-button
          v-if="data.item.contacts_info.find(e => e.type === 'MAIN')"
          :id="`tooltip-chat-wpp-${data.item.bariatric_journey_id}`"
          variant="link"
          :href="createUrl(data.item.contacts_info.find(e => e.type === 'MAIN').phone)"
          target="_blank"
        >
          <b-img :src="require('@/assets/images/icons/Icon-whatsapp.svg')" />
        </b-button>
        <b-button
          v-else
          :id="`tooltip-register-wpp-${data.item.bariatric_journey_id}`"
          variant="link"
          @click="data.item.bariatric_journey_status === 'REGISTER_PROTOCOL' ? action(data.item, 'register') : action(data.item, 'detail')"
        >
          <b-img :src="require('@/assets/images/icons/icone-whatsapp-add.svg')" />
        </b-button>
        <b-tooltip
          :target="`tooltip-register-wpp-${data.item.bariatric_journey_id}`"
          triggers="hover"
        >
          Cadastrar contato WhatsApp
        </b-tooltip>
        <b-tooltip
          :target="`tooltip-chat-wpp-${data.item.bariatric_journey_id}`"
          triggers="hover"
        >
          Conversar por WhatsApp
        </b-tooltip>
      </template>

      <template #cell(member_name)="data">
        <b-media vertical-align="center">
          <span class="text-gray-500 font-weight-bold">{{ data.item.member_name }}</span>
          <br>
          <template v-if="data.item.member_birthdate">
            <small>{{ formatDateBR(data.item.member_birthdate) }} - {{ data.item.member_age }} anos</small>
          </template>
          <br>
          <template v-if="data.item.bariatric_journey_status">
            <small>
              <b-badge
                pill
                :variant="`light-${resolveBariatricStatusVariant(data.item.bariatric_journey_status)}`"
              >
                {{ translateBr(data.item.bariatric_journey_status) }}
              </b-badge>
            </small>
          </template>
        </b-media>
      </template>

      <template #cell(days_passed)="data">
        <b-media vertical-align="center">
          <div v-if="data.item.days_past_first_appointment !== null">
            <div>
              {{ formatDateBR(data.item.first_appointment_date) }}
            </div>
            <b-badge
              :variant="`light-${resolveBariatricDaysVariant(data.item.days_past_first_appointment)}`"
              class="pr-2 pl-2"
            >
              {{ formatDay(data.item.days_past_first_appointment) }}
            </b-badge>
            <br>
            <template v-if="data.item.clinic_name">
              <small class="">{{ data.item.clinic_name }}</small>
            </template>
            <template v-else>
              <small>Local não cadastrado</small>
            </template>
          </div>
        </b-media>
      </template>

      <template #cell(source_request)="data">
        <div v-if="data.item.source_request_id !== null">
          <small><strong>{{ resolveSourceRequestId(data.item.source_request_id) }}</strong></small>
          <br>
          <template v-if="data.item.health_insurance_name !== null">
            <small> {{ data.item.health_insurance_name }} </small>
          </template>
        </div>
      </template>

      <template #cell(journeyProgress)="data">

        <div class="d-flex align-items-center">
          <span>{{ progressConcluded(data.item.journey_progress, data.item.source_request_id) }}</span>

          <b-button
            :id="`popover-${data.item.bariatric_journey_id}`"
            variant="flat-primary"
            class="btn-icon rounded-circle p-0 ml-1"
            @click="progressPopover(data.item.journey_progress)"
          >
            <feather-icon
              icon="InfoIcon"
              stroke="blue"
              size="16"
            />
          </b-button>

          <b-popover
            :target="`popover-${data.item.bariatric_journey_id}`"
            variant="primary"
            triggers="click blur"
            custom-class="bariatric-popover"
          >
            <template #title>
              <h6 class="text-white m-0">
                Progresso da jornada
              </h6>
            </template>

            <p>
              <feather-icon
                :icon="classText[0] === 'decoration' ? 'CheckCircleIcon' : 'ClockIcon'"
                :stroke="stroke[0]"
                size="14"
              />
              <small
                class="text-black-50"
                :class="classText[0]"
              >
                Primeira consulta
              </small>
            </p>

            <p>
              <feather-icon
                :icon="classText[1] === 'decoration' ? 'CheckCircleIcon' : 'ClockIcon'"
                :stroke="stroke[1]"
                size="14"
              />
              <small
                class="text-black-50"
                :class="classText[1]"
              >
                Compartilhar com cirurgião
              </small>
            </p>

            <p>
              <feather-icon
                :icon="classText[2] === 'decoration' ? 'CheckCircleIcon' : 'ClockIcon'"
                :stroke="stroke[2]"
                size="14"
              />
              <small
                class="text-black-50"
                :class="classText[2]"
              >
                Equipe multidisciplinar
              </small>
            </p>

            <p>
              <feather-icon
                :icon="classText[3] === 'decoration' ? 'CheckCircleIcon' : 'ClockIcon'"
                :stroke="stroke[3]"
                size="14"
              />
              <small
                class="text-black-50"
                :class="classText[3]"
              >
                Realização de exames
              </small>
            </p>
            <p v-if="data.item.source_request_id === 3">
              <feather-icon
                :icon="classText[4] === 'decoration' ? 'CheckCircleIcon' : 'ClockIcon'"
                :stroke="stroke[4]"
                size="14"
              />
              <small
                class="text-black-50"
                :class="classText[4]"
              >
                Autorização da cirurgia
              </small>
            </p>

            <p>
              <feather-icon
                :icon="classText[5] === 'decoration' ? 'CheckCircleIcon' : 'ClockIcon'"
                :stroke="stroke[5]"
                size="14"
              />
              <small
                class="text-black-50"
                :class="classText[5]"
              >
                Realização da cirurgia
              </small>
            </p>
          </b-popover>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="4"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        />
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="4"
          class="d-flex align-items-center justify-content-center justify-content-sm-center"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalBariatrics"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
        <b-col
          cols="12"
          sm="4"
          class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1"
        >
          <label>Mostrar</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50 viewItens"
          />
          <label>itens</label>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  BRow, BCol, BTable, BMedia, BBadge, BButton, BFormCheckbox, BFormCheckboxGroup,
  BPagination, VBModal, BFormInput, BInputGroup, BInputGroupAppend, BButtonGroup,
  BPopover, BCardTitle, BCardHeader, BImg, BForm, BTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useBariatricsList from './useBariatricsList'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BMedia,
    BBadge,
    BButton,
    BFormCheckbox,
    BFormCheckboxGroup,
    BPagination,
    BFormInput,
    BInputGroup,
    BButtonGroup,
    BInputGroupAppend,
    BPopover,
    BCardTitle,
    BCardHeader,
    BImg,
    BForm,
    BTooltip,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const isAddNewBariatricSidebarActive = ref(false)

    const {
      fetchBariatrics,
      tableColumns,
      perPage,
      currentPage,
      totalBariatrics,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBariatricListTable,
      refetchData,
      dateStart,
      dateEnd,
      queryStringFilter,
      statusOptions,
      healthInsuranceDisabled,
      enableSelecHealthInsurance,
      loadHealthInsurancePlans,
      sourceRequestsSelected,
      healthInsuranceOptions,
      loadBariatricClinics,
      healthInsuranceIds,
      clinicOptions,
      clincIds,
      hasFilter,
      activeRageDays,
      updateFiltersOnStore,
      noDataTableText,

      // UI
      resolveBariatricRoleVariant,
      viewFilters,
      resolveBariatricRoleIcon,
      resolveBariatricDaysVariant,
      resolveBariatricStatusVariant,
      resolveQueryStringFilter,
      resolveSourceRequestId,
      showFilters,
      clearFilters,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useBariatricsList()

    return {

      // Sidebar
      isAddNewBariatricSidebarActive,

      fetchBariatrics,
      tableColumns,
      perPage,
      currentPage,
      totalBariatrics,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refBariatricListTable,
      refetchData,
      dateStart,
      dateEnd,
      queryStringFilter,
      healthInsuranceDisabled,
      enableSelecHealthInsurance,
      loadHealthInsurancePlans,
      sourceRequestsSelected,
      healthInsuranceOptions,
      loadBariatricClinics,
      clinicOptions,
      clincIds,
      healthInsuranceIds,
      hasFilter,
      activeRageDays,
      noDataTableText,

      // UI
      resolveBariatricRoleVariant,
      resolveBariatricRoleIcon,
      viewFilters,
      resolveBariatricDaysVariant,
      resolveBariatricStatusVariant,
      resolveQueryStringFilter,
      resolveSourceRequestId,
      showFilters,
      clearFilters,
      updateFiltersOnStore,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
      statusOptions,
    }
  },

  data() {
    return {
      average: '',
      optionsCare: '',
      stroke: ['#808080', '#808080', '#808080', '#808080', '#808080', '#808080'],
      classText: [],
      contactUrl: '',
      featherShow: false,
    }
  },

  computed: {
    showHelp() {
      return this.$store.state.breadcrumb.showHelp
    },
  },

  watch: {
    showHelp(value) {
      if (value === true) {
        this.$bvModal.show('modal-center')
      }
    },
  },

  mounted() {
    this.$store.commit('breadcrumb/UPDATE_SHOW_HELP', false)
    this.loadHealthInsurancePlans()
    this.loadBariatricClinics()
    this.updateFiltersOnStore()
  },

  methods: {
    formatDateBR(value) {
      if (value === null) {
        return ''
      }
      return moment(value).format('DD/MM/YYYY')
    },
    formatCell(value) {
      this.contactUrl = `https://wa.me/55${value}`
      const cell = `${value.substring(0, 2)} ${value.substring(2, 7)}-${value.substring(7, 13)}`
      return cell
    },
    action(data, action) {
      this.$store.commit('bariatric/RESET')
      this.$store.commit('protocolModel/RESET')
      this.$store.commit('bariatric/UPDATE_BARIATRIC_MEMBER', data)

      const member = { id: data.member_id }
      this.$store.commit('patient/UPDATE_PATIENT', member)
      this.$store.commit('bariatric/UPDATE_BARIATRIC_JOURNEY_ID', data.bariatric_journey_id)

      if (action === 'detail') {
        setTimeout(() => this.$router.push('bariatric-detail'), 200)
      } else {
        setTimeout(() => this.$router.push('bariatric-register'), 200)
      }
    },
    show() {
      if (this.showFilter === true) {
        this.showFilter = false
      } else {
        this.showFilter = true
      }
    },
    progressPopover(progress) {
      const progressValue = Object.values(progress)
      this.classText = []
      this.stroke = ['#808080', '#808080', '#808080', '#808080', '#808080', '#808080']
      progressValue.forEach((value, index) => {
        if (value === true) {
          this.classText[index] = 'decoration'
          this.stroke[index] = '#28C76F'
        }
      })
    },
    progressConcluded(progress, sourceRequestId) {
      const progressValue = Object.values(progress)
      let concluded = 0
      progressValue.forEach(value => {
        if (value === true) {
          concluded += 1
        }
      })
      if (sourceRequestId !== 3) {
        return `${concluded}/5`
      }
      return `${concluded}/6`
    },
    progressValue(progress, sourceRequestId) {
      const progressValue = Object.values(progress)
      let concluded = 0
      progressValue.forEach(value => {
        if (value === true) {
          concluded += 1
        }
      })
      if (sourceRequestId !== 3) {
        return (concluded * 100) / 5
      }
      return (concluded * 100) / 6
    },
    rangeDays(value) {
      this.dateStart = ''
      this.dateEnd = ''

      if (value !== this.activeRageDays) {
        this.activeRageDays = value
        switch (value) {
          case '<30':
            this.dateStart = moment().add(-30, 'days').format('YYYY-MM-DD')
            this.dateEnd = moment().format('YYYY-MM-DD')
            break
          case '>31<60':
            this.dateStart = moment().add(-60, 'days').format('YYYY-MM-DD')
            this.dateEnd = moment().add(-31, 'days').format('YYYY-MM-DD')
            break
          case '>61<90':
            this.dateStart = moment().add(-90, 'days').format('YYYY-MM-DD')
            this.dateEnd = moment().add(-61, 'days').format('YYYY-MM-DD')
            break
          case '>91':
            this.dateEnd = moment().add(-91, 'days').format('YYYY-MM-DD')
            break
          default:
            break
        }
      } else {
        this.activeRageDays = null
      }
    },
    clearQueryStringFilter() {
      this.$router.replace({ query: null })
    },
    formatDay(value) {
      if (value === 1) {
        return `${value} dia`
      }
      return `${value} dias`
    },
    translateBr(value) {
      switch (value) {
        case 'REGISTER_PROTOCOL':
          return 'Cadastro de protocolo'
        case 'ACTIVE':
          return 'Ativo'
        case 'ARCHIVED':
          return 'Arquivado'
        case 'SURGERY_DONE':
          return 'Cirurgia realizada'
        default:
          return ''
      }
    },
    createUrl(cellPhone) {
      const cell = cellPhone.slice(2)

      if (cell.length === 11 && (cell.substring(2, 3) === '7' || cell.substring(2, 3) === '8' || cell.substring(2, 3) === '9')) {
        return `https://wa.me/55${cell}`
      }
      if (cell.length >= 10) {
        return `tel:55${cell}`
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}
.notification-filter {
  background-color: #fff3cd;
}
.bariatric-list-table .table thead th {
  vertical-align: middle;
  font-size: 1rem;
  letter-spacing: initial;
  text-transform: none;
  color: #171725;
}

.bariatric-list-table .badge {
  padding: 0.6rem 1rem;
}

.bariatric-list-table .table th{
  padding: 1.2rem 2rem;
  border-bottom: 0;
}

.bariatric-list-table .table td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.bariatric-list-filter .custom-control-inline {
  margin-right: 0.3rem;
}

.bariatric-progress {
  min-width: 70px;
  margin: 0 10px;
}

.bariatric-popover p {
  margin-bottom: 5px;
}

.bariatric-popover .feather {
  margin-right: 5px;
}

.filter-range-days .btn-outline-primary:not(:disabled):not(.active):focus {
  background-color: initial;
}

[dir] .badge.badge-light-blue {
  background-color: #56B4E9;
}

.badge.badge-light-blue {
  color: #fff !important;
}

[dir] .badge.badge-light-green {
  background-color: #109E73;
}

.badge.badge-light-green {
  color: #fff !important;
}

[dir] .badge.badge-light-orange {
  background-color: #E69F27;
}

.badge.badge-light-orange {
  color: #fff !important;
}

[dir] .badge.badge-light-orange-2 {
  background-color: #D55E00;
}

.badge.badge-light-orange-2 {
  color: #fff !important;
}

.decoration{
  text-decoration: line-through;
}
.badge-dote {
    position: fixed;
    margin-top: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
   /* Defina a cor desejada */
  }
</style>

<style scope>
.viewItens .vs__dropdown-menu {
  margin-top: -190px;
}

</style>
