import {
  ref, watch, computed,
} from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useBariatricCardsList() {
  // Use toast
  const toast = useToast()

  const refBariatricCardListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'selected', label: 'Seelec', sortable: false },
    { key: 'MEMBER_NAME', label: 'PACIENTE', sortable: true },
    { key: 'date_surgery_type', label: 'DATA E TIPO DA CIRURGIA', sortable: false },
    { key: 'CREATED_AT', label: 'DATA DA SOLICITAÇÃO', sortable: true },
    { key: 'STATUS', label: 'STATUS', sortable: true },
    {
      key: 'actions', label: 'AÇÃO', sortable: false, thStyle: { width: '26%' },
    },
  ]
  const perPage = ref(10)
  const totalCards = ref(0)
  const bariatricCards = ref('')
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('')
  const isSortDirDesc = ref(true)
  const isAbleToRollback = ref(true)
  const selectedCards = ref([])
  const checkedToggleAll = ref(false)
  const isApproved = ref(false)
  const emptyTextTable = ref('Você ainda não possui pacientes que solicitaram a carteirinha.')

  const dataMeta = computed(() => {
    const localItemsCount = refBariatricCardListTable.value ? refBariatricCardListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCards.value,
    }
  })

  const refetchData = () => {
    refBariatricCardListTable.value.refresh()
  }

  watch(() => store.getters['bariatric/bariatricCardListChange'], () => {
    refetchData()
  })

  watch([currentPage, perPage, searchQuery, sortBy, isSortDirDesc], () => {
    refetchData()
  })

  const fetchBariatricCards = (ctx, callback) => {
    store
      .dispatch('bariatric/fetchBariatricCards', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: !sortBy.value ? '' : sortBy.value,
        sortDirection: !isSortDirDesc.value ? 'asc' : 'desc',
      })
      .then(response => {
        const { bariatric_cards_info, total } = response.data.data
        emptyTextTable.value = 'Você ainda não possui pacientes que solicitaram a carteirinha.'
        if (total === 0 && searchQuery.value !== '') {
          emptyTextTable.value = 'Não conseguimos encontrar nenhum paciente para a sua busca.'
        }
        callback(bariatric_cards_info)
        totalCards.value = total
        // eslint-disable-next-line
        bariatricCards.value = bariatric_cards_info
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ops... Algo deu errado, tente novamente',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  async function updateCardStatus(cardIds, status, isRollback = false) {
    const titleToast = status === 'APPROVED' ? 'Solicitação de carteirinha aprovada com sucesso!' : 'Solicitação de carteirinha rejeitada com sucesso!'

    const payload = {
      bariatric_card_ids: cardIds,
      status,
      is_rollback: isRollback,
    }

    await store
      .dispatch('bariatric/updateBariatricCardsStatus', payload)
      .then(() => {
        if (isAbleToRollback.value) {
          refetchData()
          return toast({
            component: ToastificationContent,
            props: {
              title: titleToast,
              icon: 'CheckCircleIcon',
              variant: 'success',
              button: true,
              buttonColor: 'primary',
              textButton: 'Desfazer',
            },
            listeners: {
              buttonClicked: () => {
                isAbleToRollback.value = false
                updateCardStatus(cardIds, 'APPROVED', true)
                  .then(() => {
                    toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Ação desfeita com sucesso',
                        icon: 'CheckCircleIcon',
                        variant: 'success',
                      },
                    })
                  })
              },
            },
          })
        }
        return refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ops... Algo deu errado, tente novamente',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    isAbleToRollback.value = true
    selectedCards.value = []
    checkedToggleAll.value = false
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveBariatricCardStatusVariant = status => {
    if (status === 'WAITING') return 'warning'
    if (status === 'REFUSED') return 'dark'
    if (status === 'APPROVED') return 'success'
    return 'dark'
  }

  return {
    fetchBariatricCards,
    tableColumns,
    perPage,
    currentPage,
    totalCards,
    bariatricCards,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBariatricCardListTable,
    selectedCards,
    checkedToggleAll,
    isApproved,
    emptyTextTable,

    resolveBariatricCardStatusVariant,
    refetchData,
    updateCardStatus,

  }
}
