export default {
  options: {
    chart: {
      type: 'bar',
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [{
            from: 0,
            to: 100,
            color: '#3279EB',
          }],
        },
        horizontal: true,
        barHeight: '80%',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter(val) {
        return `${val}%`
      },
      offsetX: 20,
      textAnchor: 'start',
      distributed: true,
      background: {
        enabled: true,
        foreColor: 'black',
        padding: 0,
        borderRadius: 2,
        borderColor: '#fff',
        opacity: 0,
      },
    },
    tooltip: {
      enabled: false,
    },
    xaxis: {
      tickAmount: 4,
      max: 100,
      labels: {
        formatter(val) {
          return `${val}%`
        },
      },
    },
    responsive: [
      {
        breakpoint: 1470,
        options: {
          dataLabels: {
            style: {
              fontSize: '12px',
            },
          },
          xaxis: {
            labels: {
              style: {
                fontSize: '12px',
              },
            },
          },
        },
      },
    ],
  },
}
