<template>
  <div
    class="p-1"
    style="background-color: #ffffff"
  >
    <b-card-header header-bg-variant="white">
      <div class="w-100">
        <b-button
          variant="outline-primary"
          @click="create(); sourceTab('protocol')"
        >
          Cadastrar um protocolo modelo
        </b-button>
        <b-row>
          <b-col>
            <b-card-title class="mt-3 text-gray-800">
              Lista de protocolos cadastrados
            </b-card-title>
          </b-col>
          <b-col md="auto">
            <transition-group
              name="fade"
              :duration="150"
            >
              <b-button
                v-if="selected.length === 1"
                key="edit"
                variant="outline-primary"
                class="ml-1 mt-2"
                @click="edit(); sourceTab('protocol')"
              >
                Editar
              </b-button>
              <b-button
                v-if="selected.length > 0"
                key="remove"
                variant="outline-danger"
                class="ml-1 mt-2"
                @click="show = true"
              >
                Excluir
              </b-button>
            </transition-group>
          </b-col>
        </b-row>
      </div>
    </b-card-header>
    <b-table
      ref="refProtocolModelListTable"
      :items="listProtocolModels"
      :fields="tableColumns"
      primary-key="id"
      responsive
      show-empty
      hover
      empty-text="Você ainda não possui protocolos modelo adicionados."
      :sort-by.sync="sortBy"
      :sort-desc.sync="isSortDirDesc"
      class="protocol-list-table"
      selectable
      @row-selected="onRowSelected"
    >
      <template #cell(name)="data">
        <span class="text-gray-500 font-weight-bold">{{ data.item.name }}</span>
      </template>

      <template
        #cell(created_at)="data"
        class="text-gray-500"
      >
        <span class="text-gray-500">{{ formatDateBR(data.item.created_at) }}</span>
      </template>

      <template #cell(selection)="data">
        <div class="text-nowrap">
          <b-form-checkbox
            v-model="selected"
            :value="data.item.id"
            @change="checked(data.index, data.rowSelected)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col class="d-flex align-items-center justify-content-center">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalProtocolModels"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>

        <b-col
          md="auto"
          class="d-flex align-items-center justify-content-end"
        >
          <label>Mostrar</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>itens</label>
        </b-col>
      </b-row>
    </div>
    <b-modal
      v-model="show"
      centered
      cancel-title="Cancelar"
      cancel-variant="flat-primary"
      ok-variant="danger"
      ok-title="Excluir"
      hide-header
      footer-class="pt-0 border-0 justify-content-center"
      content-class="p-2"
      @ok="remove"
    >
      <b-card-text class="text-center">
        <feather-icon
          icon="AlertCircleIcon"
          stroke="red"
          size="80"
        />
        <div class="mt-2 text-gray-800 h3">
          <strong>{{ `${selected.length > 1 ? 'Deseja excluir estes protocolos modelo?' : 'Deseja excluir este protocolo modelo?'}`, }}</strong>
        </div>
        <div class="my-1 text-gray-800 h5">
          Esta ação não poderá ser desfeita.
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import moment from 'moment'
import vSelect from 'vue-select'
import {
  BButton, BRow, BCol, BTable, BCardHeader, BCardTitle, BFormCheckbox,
  BPagination, VBModal, BCardText,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useProtocolModelList from './useProtocolModelList'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BTable,
    BPagination,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardText,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const {
      listProtocolModels,
      tableColumns,
      perPage,
      currentPage,
      totalProtocolModels,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refProtocolModelListTable,
      refetchData,
      protocolModelSelected,
    } = useProtocolModelList()

    return {
      listProtocolModels,
      tableColumns,
      perPage,
      currentPage,
      totalProtocolModels,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refProtocolModelListTable,
      refetchData,
      protocolModelSelected,
    }
  },

  data() {
    return {
      selected: [],
      show: false,
    }
  },

  mounted() {
    this.$store.commit('breadcrumb/UPDATE_SHOW_HELP', false)
  },

  methods: {
    formatDateBR(value) {
      return value ? moment(value).format('DD/MM/YYYY') : null
    },
    onRowSelected(items) {
      this.selected = items.map(d => d.id)
    },
    checked(index, checked) {
      if (checked === false) {
        this.$refs.refProtocolModelListTable.selectRow(index)
      } else {
        this.$refs.refProtocolModelListTable.unselectRow(index)
      }
    },
    sourceTab(tab) {
      this.$store.commit('bariatric/UPDATE_ACTION_TAB', tab)
    },
    create() {
      this.$store.commit('protocolModel/RESET')
      this.$store.commit('protocolModel/UPDATE_ACTION', 'register')
      this.$router.push('protocol-model-create')
    },
    remove() {
      this.$store
        .dispatch('protocolModel/remove', { data: this.selected })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${this.selected.length > 1 ? 'Protocolos modelo excluídos com sucesso!' : 'Protocolo modelo excluído com sucesso!'}`,
              text: `${this.selected.length > 1 ? 'Os protocolos foram excluídos da lista de protocolos modelo cadastrados.' : 'O protocolo foi excluído da lista de protocolos modelo cadastrados.'}`,
              size: 'sm',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          this.selected = []
          this.$refs.refProtocolModelListTable.refresh()
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao excluir protocolo(s) modelo',
              text: 'Ops... Algo deu errado, tente novamente',
              size: 'sm',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    edit() {
      if (this.selected.length > 0) {
        this.$store.commit('protocolModel/RESET')
        this.$store.commit('protocolModel/UPDATE_ID_SELECT', this.selected[0])
        this.$store.commit('protocolModel/UPDATE_ACTION', 'register')
        this.$router.push('protocol-model-edit')
      }
    },
  },
}
</script>

<style lang="scss" scope>
// Add to global styles
.protocol-list-table .table thead th {
  vertical-align: middle;
  font-size: 1rem;
  letter-spacing: initial;
  text-transform: none;
  color: #171725;
}
.per-page-selector {
  width: 90px;
}

.protocol-list-table .table th{
  padding: 1.2rem 2rem;
  border-bottom: 0;
}

.protocol-list-table .table td {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
</style>
