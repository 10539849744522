<template>
  <div>
    <b-modal
      id="confirm-modal"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      centered
      hide-footer
      hide-header
      hide-header-close
    >
      <b-container class="p-2">
        <b-row>
          <b-col class="text-center mb-2">
            <feather-icon
              icon="InfoIcon"
              size="90"
              :stroke="isApproved ? '#3279eb' : '#ea5455'"
              stroke-width="1.5"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <h4><strong>Atenção</strong></h4>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <p v-if="isApproved">
              Você confirma a aprovação da solicitação de carteirinha?
            </p>
            <p v-else>
              Tem certeza de que deseja recusar a solicitação de carteirinha?
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <b-button
              variant="outline"
              class="mr-1 text-primary"
              @click="$bvModal.hide('confirm-modal')"
            >
              Cancelar
            </b-button>
            <b-button
              :variant="isApproved ? 'primary' : 'danger'"
              @click="confirm"
            >
              <span v-if="isApproved">Aprovar</span>
              <span v-else>Recusar</span>
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import {
  BModal, BContainer, BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BContainer,
    BRow,
    BCol,
    BButton,

  },
  props: {
    isApproved: Boolean,
  },
  methods: {
    confirm() {
      if (this.isApproved) {
        this.$bvModal.hide('confirm-modal')
        return this.$emit('approved')
      }

      this.$bvModal.hide('confirm-modal')
      return this.$emit('refused')
    },
  },
}
</script>
