<template>
  <div>
    <b-tabs nav-wrapper-class="custom-nav-wrapper">
      <b-tab
        title="Jornada bariátrica"
        :active="action==='journey'? true : false"
        @click="sourceTab('journey')"
      >
        <template #title>
          <feather-icon icon="FlagIcon" /> Jornada bariátrica
        </template>
        <bariatric-list />
      </b-tab>

      <b-tab
        title="Protocolos modelo"
        :active="action==='protocol'? true : false"
        @click="sourceTab('protocol')"
      >
        <template #title>
          <feather-icon icon="ClipboardIcon" /> Protocolos modelo
        </template>
        <protocol-model-list />
      </b-tab>
      <b-tab
        title="Contato para paciente"
        :active="action==='contact'? true : false"
        @click="sourceTab('contact')"
      >
        <template #title>
          <feather-icon icon="PhoneIcon" /> Contato para paciente
        </template>
        <bariactri-contact />
      </b-tab>
      <b-tab
        title="Indicadores"
        :active="action==='indicators'? true : false"
        lazy
        @click="sourceTab('indicators')"
      >
        <template #title>
          <feather-icon icon="BarChartIcon" /> Indicadores
          <span
            v-if="indicatorsIsNewItemTab"
            class="badge-dot"
          />
        </template>
        <Bariatric-indicator />
      </b-tab>
      <b-tab
        title="Carteirinha"
        :active="action==='card'? true : false"
        @click="sourceTab('card')"
      >
        <template #title>
          <feather-icon icon="UserCheckIcon" /> Carteirinha
          <span
            v-if="cardIsNewItemOnLayout"
            class="badge-dot"
          />
        </template>
        <bariatric-card />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import BariatricList from './Bariatric-list.vue'
import ProtocolModelList from './protocol-model/ProtocolModel-list.vue'
import BariactriContact from './Bariatric-contact.vue'
import BariatricIndicator from './indicator/Bariatric-indicator.vue'
import BariatricCard from './card/Bariatric-card.vue'

export default {
  components: {
    BTabs,
    BTab,
    BariatricList,
    ProtocolModelList,
    BariactriContact,
    BariatricIndicator,
    BariatricCard,
  },
  data() {
    return {
      cardIsNewItemOnLayout: this.$store.state.notificationConfig.cardIsNewItemTab,
      indicatorsIsNewItemTab: this.$store.state.notificationConfig.indicatorsIsNewItemTab,
    }
  },
  computed: {
    action() {
      return this.$store.getters['bariatric/actionTab']
    },
  },

  watch: {
    action() {
      this.sourceTab(this.action)
    },
  },

  created() {
    if (this.$route.query.tab) {
      this.$store.commit('bariatric/UPDATE_ACTION_TAB', this.$route.query.tab)
      return
    }

    this.$store.commit('bariatric/UPDATE_ACTION_TAB', 'journey')
  },

  methods: {
    sourceTab(tab) {
      this.$store.commit('bariatric/UPDATE_ACTION_TAB', tab)

      if (tab === 'card' && this.$store.state.notificationConfig.cardIsNewItemTab) {
        const badgeConfig = [{ notification_type: 'BARIATRIC_CARD', is_active: false }]
        this.updateTabStatusBadge(badgeConfig)
          .then(() => {
            this.$store.commit('notificationConfig/UPDATE_CARD_IS_NEW_ITEM_TAB', false)
            this.cardIsNewItemOnLayout = false
          })
      }
      if (tab === 'indicators' && this.$store.state.notificationConfig.indicatorsIsNewItemTab) {
        const badgeConfig = [{ notification_type: 'INDICATORS', is_active: false }]
        this.updateTabStatusBadge(badgeConfig)
          .then(() => {
            this.$store.commit('notificationConfig/UPDATE_INDICATORS_IS_NEW_ITEM_TAB', false)
            this.indicatorsIsNewItemTab = false
          })
      }
    },
    async updateTabStatusBadge(badge) {
      return this.$store.dispatch('notificationConfig/save', badge)
    },
  },
}
</script>
<style>
.custom-nav-wrapper {
  background-color: #ffffff;
  padding: 15px;
}
  .badge-dot {
    position: absolute;
    top: 3px;
    right: 12px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #EA5455; /* Defina a cor desejada */
  }
</style>
