<template>
  <div>
    <h5 class="font-weight-normal mb-3">
      Percentual de pacientes <br> que realizaram a cirurgia
      <b-button
        id="popover-card-patients-surgery-done"
        variant="flat-primary"
        class="btn-icon rounded-circle p-0"
      >
        <feather-icon
          icon="InfoIcon"
          stroke="blue"
          size="16"
        />
      </b-button>
    </h5>

    <b-skeleton
      v-if="loading"
      animation="wave"
      width="20%"
      height="30px"
      type="text"
    />
    <span
      v-else-if="!loading && percent !== null"
      class="h1 font-weight-bolder d-block text-gray-800"
    >
      <strong>{{ percent }}%</strong>
    </span>
    <p v-else>
      Ops! Este conteúdo não pode ser exibido. Por favor verifique sua conexão com a internet e tente novamente.
    </p>

    <b-popover
      target="popover-card-patients-surgery-done"
      variant="primary"
      triggers="click blur"
      custom-class="bariatric-popover"
    >
      <template #title>
        <h6 class="text-white m-0">
          Percentual de pacientes que realizaram a cirurgia
        </h6>
      </template>
      <p>Consulte o percentual de todos os seus pacientes que realizam a cirurgia bariátrica.</p>
    </b-popover>
  </div>
</template>

<script>

import {
  BButton, BPopover, BSkeleton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BPopover,
    BSkeleton,
  },

  props: {
    totalPatients: {
      type: Number,
      default: 0,
    },
    surgeriesPerformed: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      percent: null,
    }
  },

  watch: {
    totalPatients() {
      if (this.totalPatients !== 0) {
        this.percent = Math.round((this.surgeriesPerformed * 100) / this.totalPatients)
      } else {
        this.percent = 0
      }
    },
  },
}
</script>

<style lang="scss" scoped>
  .card-patients-surgery-done{
    h5 {
      line-height: 1.5rem;
    }
  }
</style>
