<template>
  <div
    class="p-1"
    style="background-color: #ffffff"
  >
    <h4 class="ml-1">
      Cadastre um telefone de contato para os pacientes
    </h4>
    <p class="ml-1">
      Nesta página, você pode cadastrar um número de celular com WhatsApp. O contato será compartilhado com seus pacientes cadastrados para que eles entrem em contato com você sempre que necessário.
    </p>
    <div>
      <validation-observer ref="bariatricRules">
        <b-form>
          <div>
            <b-row>
              <b-col class="col-4 mt-2 ml-1">
                <b-form-group
                  label="Número de celular com WhatsApp:"
                  label-for="cellPhone"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="número de celular"
                    rules="required"
                  >
                    <b-input-group>
                      <cleave
                        id="cellPhone"
                        v-model="cellPhone"
                        class="form-control"
                        :raw="true"
                        :options="options.cell"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Ex: (99) 99999-9999"
                      />

                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                class="mt-2 ml-1"
                cols="6"
              >
                <b-button
                  type="button"
                  variant="flat-primary"
                  title="Cancelar"
                  @click="cancel"
                >
                  Cancelar
                </b-button>
                <b-button
                  v-if="showButtonRemove"
                  type="button"
                  variant="outline-danger"
                  class="ml-2"
                  :disabled="buttonDisable"
                  title="Excluir"
                  @click="remove"
                >
                  Excluir
                </b-button>
                <b-button
                  type="button"
                  variant="primary"
                  class="ml-2"
                  :disabled="buttonDisable"
                  title="Salvar"
                  @click="save"
                >
                  Salvar
                </b-button>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
    </div>
    <BariatricModal />
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BFormGroup, BInputGroup, BButton,
} from 'bootstrap-vue'
import BariatricModal from '@/layouts/components/surveys/BariatricModal.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BButton,

    Cleave,

    ValidationProvider,
    ValidationObserver,
    BariatricModal,
  },

  data() {
    return {
      cellPhone: '',
      buttonDisable: false,
      showButtonRemove: true,
      options: {
        cell: {
          numericOnly: true,
          blocks: [0, 2, 5, 4],
          delimiters: ['(', ') ', '-'],
        },
      },
    }
  },

  created() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.$store.dispatch('bariatric/showWorker')
        .then(response => {
          const cellPhone = response.data.data.worker_info.cellphone
          if (cellPhone === null) {
            this.cellPhone = ''
            this.$refs.bariatricRules.reset()
            this.showButtonRemove = false
          } else {
            this.cellPhone = cellPhone.substring(2)
            this.showButtonRemove = true
            this.$store.commit('survey/UPDATE_BARIATRIC_SURVEY', true)
          }
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ops... Algo deu errado, tente novamente',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    },
    save() {
      this.$refs.bariatricRules.validate().then(success => {
        if (success) {
          this.buttonDisable = true
          const dataForm = {
            worker_info: {
              cellphone: `55${this.cellPhone}`,
            },
          }
          this.$store.dispatch('bariatric/saveContact', dataForm)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Contato cadastrado com sucesso!',
                  text: 'Seus pacientes cadastrados já podem visualizar seu número no aplicativo CUCO.',
                  size: 'sm',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })

              this.$store.commit('survey/UPDATE_BARIATRIC_SURVEY', true)

              this.loadData()
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Erro ao salvar o contato',
                  text: 'Ops... Algo deu errado, tente novamente',
                  size: 'sm',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
            .finally(() => {
              this.buttonDisable = false
            })
        }
      })
    },
    remove() {
      this.$swal({
        title: 'Deseja excluir o número de contato cadastrado?',
        text: 'Esta ação não poderá ser desfeita.',
        icon: 'warning',
        iconColor: '#eb5356',
        reverseButtons: true,
        showCancelButton: true,
        confirmButtonText: 'Excluir',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-danger ml-1',
          cancelButton: 'btn btn-flat-primary',
        },
        buttonsStyling: false,
      })
        .then(result => {
          this.buttonDisable = false
          if (result.isConfirmed) {
            const dataForm = {
              worker_info: {
                cellphone: null,
              },
            }
            this.$store.dispatch('bariatric/saveContact', dataForm)
              .then(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Contato excluído com sucesso!',
                    text: 'Seus pacientes cadastrados não visualizarão mais seu número de contato no aplicativo CUCO.',
                    size: 'sm',
                    icon: 'CheckCircleIcon',
                    variant: 'success',
                  },
                })

                this.loadData()
              })
              .catch(() => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Erro ao excluir o contato',
                    text: 'Ops... Algo deu errado, tente novamente',
                    size: 'sm',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          }
        })
    },
    cancel() {
      this.loadData()
      this.$store.commit('bariatric/UPDATE_ACTION_TAB', 'journey')
    },
  },
}
</script>
