import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import { useRouter } from '@core/utils/utils'

async function checkIfOpenNPSBariatric(list) {
  if (list.find(item => item.bariatric_journey_status !== 'REGISTER_PROTOCOL')) {
    store.commit('survey/UPDATE_BARIATRIC_SURVEY', true)
  }
}

export default function useBariatricsList() {
  // Use toast
  const toast = useToast()

  const refBariatricListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'actions', label: 'AÇÃO', sortable: false, thStyle: { width: '20%' },
    },
    { key: 'member_name', label: 'PACIENTE E STATUS', sortable: true },
    { key: 'days_passed', label: 'DADOS DA 1º CONSULTA', sortable: true },
    { key: 'source_request', label: 'TIPO DA SOLICITAÇÃO', sortable: false },
    { key: 'journeyProgress', label: 'PROGRESSO DA JORNADA' },
  ]

  const statusOptions = [
    { key: 'ACTIVE', name: 'Ativo' },
    { key: 'REGISTER_PROTOCOL', name: 'Cadastro de protocolo' },
    { key: 'ARCHIVED', name: 'Arquivado' },
    { key: 'SURGERY_DONE', name: 'Cirurgia realizada' },
  ]
  const perPage = ref(10)
  const totalBariatrics = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('default')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const planFilter = ref(null)
  const dateStart = ref(store.state.bariatricListFilter.startDate)
  const dateEnd = ref(store.state.bariatricListFilter.endDate)
  const healthInsuranceDisabled = ref(true)
  const healthInsuranceOptions = ref([])
  const sourceRequestsSelected = ref(store.state.bariatricListFilter.sourceRequest)
  const healthInsuranceIds = ref(store.state.bariatricListFilter.healthInsuranceIds)
  const clincIds = ref(store.state.bariatricListFilter.clinicsIds)
  const clinicOptions = ref([])
  const statusFilter = ref(store.state.bariatricListFilter.status)
  const filtersType = ['REGISTER_PROTOCOL', 'HEALTH_SPECIALIST', 'EXAM', 'SURGERY_REQUEST', 'SURGERY_REQUEST_APPROVED', 'SURGERY', 'SURGERY_DONE']
  const { route } = useRouter()
  const viewFilters = ref(false)
  const hasFilter = ref(false)
  const activeRageDays = ref(store.state.bariatricListFilter.activeRageDays)
  const noDataTableText = ref('')

  const queryStringFilter = computed(() => route.value.query.filter)

  const dataMeta = computed(() => {
    const localItemsCount = refBariatricListTable.value ? refBariatricListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalBariatrics.value,
    }
  })

  function enableSelecHealthInsurance(checkboxes) {
    if (checkboxes.find(checkbox => checkbox === 'HEALTH_INSURANCE')) {
      healthInsuranceDisabled.value = false
      return
    }
    healthInsuranceDisabled.value = true
  }

  function updateFiltersOnStore() {
    store.commit('bariatricListFilter/UPDATE_STATUS', statusFilter.value)
    store.commit('bariatricListFilter/UPDATE_START_DATE', dateStart.value)
    store.commit('bariatricListFilter/UPDATE_END_DATE', dateEnd.value)
    store.commit('bariatricListFilter/UPDATE_CLINICS_IDS', clincIds.value)
    store.commit('bariatricListFilter/UPDATE_HEALTH_INSURANCE_IDS', healthInsuranceIds.value)
    store.commit('bariatricListFilter/UPDATE_SOURCE_REQUEST', sourceRequestsSelected.value)
    store.commit('bariatricListFilter/UPDATE_ACTIVE_RANGE_DATE', activeRageDays.value)

    enableSelecHealthInsurance(sourceRequestsSelected.value)

    if (dateStart.value !== '' || dateEnd.value !== '' || statusFilter.value.length !== 0 || sourceRequestsSelected.value.length !== 0 || clincIds.value.length !== 0) {
      hasFilter.value = true
      viewFilters.value = true
      noDataTableText.value = 'Não conseguimos encontrar nenhum paciente para a sua busca.'
      return
    }
    hasFilter.value = false

    noDataTableText.value = 'Você ainda não possui pacientes adicionados.'
  }

  const refetchData = () => {
    refBariatricListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, planFilter, dateStart, dateEnd, statusFilter, sortBy, isSortDirDesc, queryStringFilter, sourceRequestsSelected, healthInsuranceIds, clincIds], () => {
    updateFiltersOnStore()
    refetchData()

    if (searchQuery.value !== '') {
      noDataTableText.value = 'Não conseguimos encontrar nenhum paciente para a sua busca.'
    }
  })

  const fetchBariatrics = (ctx, callback) => {
    store
      .dispatch('bariatric/fetchBariatrics', {
        type: filtersType.includes(queryStringFilter.value) ? queryStringFilter.value : null,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: !sortBy.value ? 'default' : sortBy.value,
        sortDirection: !isSortDirDesc.value ? 'asc' : 'desc',
        role: roleFilter.value,
        plan: planFilter.value,
        dateStart: dateStart.value,
        dateEnd: dateEnd.value,
        status: statusFilter.value.length === 0 ? ['ACTIVE', 'REGISTER_PROTOCOL', 'ARCHIVED', 'SURGERY_DONE'] : statusFilter.value,
        source_requests: sourceRequestsSelected.value,
        clinics: clincIds.value,
        healthInsurance: healthInsuranceIds.value,
      })
      .then(response => {
        const { bariatric_journeys_info, total } = response.data.data

        callback(bariatric_journeys_info)
        totalBariatrics.value = total

        checkIfOpenNPSBariatric(bariatric_journeys_info)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Ops... Algo deu errado, tente novamente',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  function loadHealthInsurancePlans(filter) {
    this.$store.dispatch('bariatric/healthInsurancePlans', filter)
      .then(response => {
        const { data } = response.data
        healthInsuranceOptions.value = data.health_insurance_plans_info
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ops... Algo deu errado, tente novamente',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  function loadBariatricClinics() {
    this.$store.dispatch('bariatric/bariatricClinics')
      .then(response => {
        const { data } = response.data
        clinicOptions.value = data.bariatric_clinics_info.map(clinic => {
          const currentClinic = clinic
          if (currentClinic.name === 'DEFAULT') {
            currentClinic.name = 'Local não cadastrado'
          }
          return currentClinic
        })
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Ops... Algo deu errado, tente novamente',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  function clearFilters() {
    dateStart.value = ''
    dateEnd.value = ''
    statusFilter.value = []
    sourceRequestsSelected.value = []
    clincIds.value = []
    healthInsuranceIds.value = []
    activeRageDays.value = ''
    healthInsuranceDisabled.value = true
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveBariatricDaysVariant = calendarDays => {
    if (calendarDays < 30) return 'blue'
    if (calendarDays > 31 && calendarDays < 60) return 'green'
    if (calendarDays > 61 && calendarDays < 90) return 'orange'
    return 'orange-2'
  }

  const resolveBariatricStatusVariant = status => {
    if (status === 'ACTIVE') return 'primary'
    if (status === 'REGISTER_PROTOCOL') return 'warning'
    if (status === 'SURGERY_DONE') return 'success'
    return 'dark'
  }

  const resolveQueryStringFilter = filter => {
    if (filter === 'REGISTER_PROTOCOL') return 'Pacientes que aguardam o cadastro de protocolo para iniciar sua jornada.'
    if (filter === 'HEALTH_SPECIALIST') return 'Pacientes que não concluíram a etapa de especialistas há mais de 35 dias.'
    if (filter === 'EXAM') return 'Pacientes que não concluíram a etapa de exames há mais de 35 dias.'
    if (filter === 'SURGERY_REQUEST') return 'Pacientes que concluíram a etapa de especialistas e ou exames mas não inseriram a data de pedido da autorização da cirurgia.'
    if (filter === 'SURGERY_REQUEST_APPROVED') return 'Pacientes com data do pedido da autorização da cirurgia ultrapassou os 21 dias úteis.'
    if (filter === 'SURGERY') return 'Pacientes com cirurgia autorizada mas que não inseriram os dados da cirurgia'
    if (filter === 'SURGERY_DONE') return 'Pacientes que a data da cirurgia foi ultrapassada e não foi marcada como realizada.'
    return ''
  }

  const resolveSourceRequestId = id => {
    if (id === 1) return 'SUS'
    if (id === 2) return 'Particular'
    if (id === 3) return 'Convênio'
    return 'dark'
  }

  function showFilters() {
    if (viewFilters.value) {
      viewFilters.value = false
      return
    }
    viewFilters.value = true
  }

  return {
    fetchBariatrics,
    tableColumns,
    perPage,
    currentPage,
    totalBariatrics,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refBariatricListTable,
    dateStart,
    dateEnd,
    queryStringFilter,
    statusOptions,
    healthInsuranceDisabled,
    sourceRequestsSelected,
    healthInsuranceOptions,
    clinicOptions,
    clincIds,
    healthInsuranceIds,
    viewFilters,
    hasFilter,
    activeRageDays,
    noDataTableText,

    resolveBariatricDaysVariant,
    resolveBariatricStatusVariant,
    refetchData,
    resolveQueryStringFilter,
    enableSelecHealthInsurance,
    loadHealthInsurancePlans,
    loadBariatricClinics,
    resolveSourceRequestId,
    showFilters,
    clearFilters,
    updateFiltersOnStore,

    // Extra Filters
    roleFilter,
    planFilter,
    statusFilter,
  }
}
